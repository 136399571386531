<template>
  <div class="procureReportYearly">
    <h1>采购月报表</h1>
    <el-form :inline="true">
      <el-form-item label="采购年份">
        <el-date-picker :clearable="false" v-model="searchParam.year" type="year" style="width: 110px;" />
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="searchParam.supplier" filterable style="width: 180px;" clearable>
          <el-option v-for="supplierItem in show.supplierList" :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" height="calc(100vh - 310px)">
      <el-table-column label="采购日期" width="180" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ scope.row.dateGe + '~' + scope.row.dateLe }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" width="120" label="预付款" show-overflow-tooltip />
      <el-table-column prop="truckQuantity" width="120" label="车数" show-overflow-tooltip />
      <el-table-column prop="productQuantity" width="120" label="煤矿数量" show-overflow-tooltip />
      <el-table-column prop="productPrice" width="120" label="煤矿平均单价" show-overflow-tooltip />
      <el-table-column prop="transportPrice" width="120" label="煤矿平均运费" show-overflow-tooltip />
      <el-table-column prop="residueAmount" width="120" label="剩余款" show-overflow-tooltip />
      <el-table-column prop="invoiceQuantity" width="120" label="开票数量" show-overflow-tooltip />
      <el-table-column prop="invoiceAmount" width="120" label="开票金额" show-overflow-tooltip />
      <el-table-column prop="productUnInvoiceQuantity" width="130" label="取货未开票数量" show-overflow-tooltip />
      <el-table-column prop="productUnInvoiceAmount" width="130" label="取货未开票金额" show-overflow-tooltip />
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
  payAmount: null,
  truckQuantity: null,
  productQuantity: null,
  invoiceQuantity: null,
  invoiceAmount: null,
})

const show = reactive({
  selected: [],
  supplierList: []
})

api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.year = new Date()
  searchParam.supplier = null
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.year) {
    ElMessage.error('请选择年份')
    return
  }
  data.list = []
  const params = {}
  params.supplier = searchParam.supplier || null
  const firstDay = new Date(searchParam.year.getFullYear(), 0, 1)
  const lastDay = new Date(searchParam.year.getFullYear() + 1, 0, 0)
  for (const date = firstDay; date <= lastDay; date.setMonth(date.getMonth() + 1)) {
    params.dateGe = util.parseTime(date, '{y}-{m}-{d}')
    const dateLe = new Date(date)
    dateLe.setMonth(date.getMonth() + 1)
    dateLe.setDate(date.getDate() - 1)
    params.dateLe = util.parseTime(dateLe, '{y}-{m}-{d}')
    api.get('/backend/statistics/procureReportBySupplier', { params: params }).then(res => {
      data.list.push(res)
      data.list.sort((a, b) => {
        return new Date(a.dateGe).getTime() - new Date(b.dateGe).getTime()
      })
    })
  }
}

const summary = () => {
  const list = data.list
  let payAmount = 0
  let truckQuantity = 0
  let productQuantity = 0
  let invoiceQuantity = 0
  let invoiceAmount = 0
  list.forEach(item => {
    payAmount = math.plus(payAmount, item.payAmount)
    truckQuantity = math.plus(truckQuantity, item.truckQuantity)
    productQuantity = math.plus(productQuantity, item.productQuantity)
    invoiceQuantity = math.plus(invoiceQuantity, item.invoiceQuantity)
    invoiceAmount = math.plus(invoiceAmount, item.invoiceAmount)
  })
  return ['合计', payAmount, truckQuantity, productQuantity, '', '', '', invoiceQuantity, invoiceAmount]
}
</script>

<style lang="less"></style>